import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { LOAD_FROM_CLOUD } from '../../data.const';
import {
  setCurrentCloudDocumentAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';
import { getUser } from '../../../auth';
import { rootConfig } from '../../../../../config/root-config';

const loadFromCloudEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(LOAD_FROM_CLOUD),
    map(({ payload }: any) => payload),
    withLatestFrom(state$),
    switchMap(([body, state]) => {
      const user = getUser(state);
      const { id: UserId } = user;
      return concat(
        of(setLoaders({ documentoLoader: true })),
        defer(() =>
          from(
            request({
              path: `LoadFromCloud`,
              method: httpMethods.POST,
              body: {
                UserId,
                ...body,
              },
              rootApi: rootConfig.documentEditorEndpoint,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return p;
            }),
            tap((p) => console.log(`[epic ${LOAD_FROM_CLOUD}]`, p)),
            switchMap((p) => of(setCurrentCloudDocumentAction(p))),
            catchError((err) => {
              console.error(`[epic ${LOAD_FROM_CLOUD}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message:
                    err.message?.trim().length > 0
                      ? err.message
                      : 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ documentoLoader: false }))
      );
    })
  );

export default loadFromCloudEpic;
