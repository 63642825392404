import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { concat, defer, EMPTY, from, of } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import { UPSERT_DOCUMENTO_ONEDRIVE } from '../../../data.const';
import { setLoaders, setPopupInfo } from '../../../data.action';

const upsertDocumentoOnedriveEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_DOCUMENTO_ONEDRIVE),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, ...rest } = payload;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return rest;
    }),
    tap((p) => console.log(`[epic ${UPSERT_DOCUMENTO_ONEDRIVE}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, state]) => {
      if (!state.data.microsoftAccountStatus?.microsoftConfigured) {
        return of();
      }

      return concat(
        of(setLoaders({ userLoader: true, page: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/onedrive/document`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            switchMap((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return EMPTY;
            }),
            catchError((err) => {
              console.error(`[epic ${UPSERT_DOCUMENTO_ONEDRIVE}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message,
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ userLoader: false, page: false }))
      );
    })
  );

export default upsertDocumentoOnedriveEpic;
