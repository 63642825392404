export const STATE_KEY = 'data';

export const GET_USER_RELATED_DATA = `${STATE_KEY}/GET_USER_RELATED_DATA`;
export const GET_USER_RELATED_DATA_SUCCESS = `${STATE_KEY}/GET_USER_RELATED_DATA_SUCCESS`;
export const SET_FILTERS = `${STATE_KEY}/SET_FILTERS`;
export const SET_LOADERS = `${STATE_KEY}/SET_LOADERS`;
export const GET_CATEGORIES = `${STATE_KEY}/GET_CATEGORIES`;
export const GET_CATEGORIES_SUCCESS = `${STATE_KEY}/GET_CATEGORIES_SUCCESS`;
export const GET_DOCUMENTS = `${STATE_KEY}/GET_DOCUMENTS`;
export const GET_DOCUMENTS_SUCCESS = `${STATE_KEY}/GET_DOCUMENTS_SUCCESS`;
export const UPLOAD_DOCUMENTS = `${STATE_KEY}/UPLOAD_DOCUMENTS`;
export const POLL_DOCUMENTS_STATUS = `${STATE_KEY}/POLL_DOCUMENTS_STATUS`;
export const SET_POPUP_INFO = `${STATE_KEY}/SET_POPUP_INFO`;
export const SET_CONFIRM_POPUP = `${STATE_KEY}/SET_CONFIRM_POPUP`;
export const DELETE_CATEGORY = `${STATE_KEY}/DELETE_CATEGORY`;
export const DELETE_DOCUMENTS = `${STATE_KEY}/DELETE_DOCUMENTS`;
export const DOWNLOAD_DOCUMENTS = `${STATE_KEY}/DOWNLOAD_DOCUMENTS`;
export const DOWNLOAD_DOCUMENTS_BY_TYPE = `${STATE_KEY}/DOWNLOAD_DOCUMENTS_BY_TYPE`;
export const GET_PROJECTS = `${STATE_KEY}/GET_PROJECTS`;
export const GET_PROJECTS_SUCCESS = `${STATE_KEY}/GET_PROJECTS_SUCCESS`;
export const DELETE_PROJECT = `${STATE_KEY}/DELETE_PROJECT`;
export const CREATE_PROJECT = `${STATE_KEY}/CREATE_PROJECT`;
export const RESET_FILTERS = `${STATE_KEY}/RESET_FILTERS`;
export const SEARCH_DOCUMENTS = `${STATE_KEY}/SEARCH_DOCUMENTS`;
export const STORE_SEARCH_DOCUMENTS = `${STATE_KEY}/STORE_SEARCH_DOCUMENTS`;
export const MODIFY_DOCUMENTS = `${STATE_KEY}/MODIFY_DOCUMENTS`;

// due diligence
export const GET_DOCUMENTS_DUE_DILIGENCE = `${STATE_KEY}/GET_DOCUMENTS_DUE_DILIGENCE`;
export const GET_DOCUMENTS_DUE_DILIGENCE_SUCCESS = `${STATE_KEY}/GET_DOCUMENTS_DUE_DILIGENCE_SUCCESS`;
export const DELETE_DOCUMENTS_DUE_DILIGENCE = `${STATE_KEY}/DELETE_DOCUMENTS_DUE_DILIGENCE`;
export const DOWNLOAD_DOCUMENTS_DUE_DILIGENCE = `${STATE_KEY}/DOWNLOAD_DOCUMENTS_DUE_DILIGENCE`;
export const GET_PROJECTS_DUE_DILIGENCE = `${STATE_KEY}/GET_PROJECTS_DUE_DILIGENCE`;
export const GET_PROJECTS_DUE_DILIGENCE_SUCCESS = `${STATE_KEY}/GET_PROJECTS_DUE_DILIGENCE_SUCCESS`;
export const DELETE_PROJECT_DUE_DILIGENCE = `${STATE_KEY}/DELETE_PROJECT_DUE_DILIGENCE`;
export const CREATE_PROJECT_DUE_DILIGENCE = `${STATE_KEY}/CREATE_PROJECT_DUE_DILIGENCE`;
export const UPLOAD_DOCUMENTS_DUE_DILIGENCE = `${STATE_KEY}/UPLOAD_DOCUMENTS_DUE_DILIGENCE`;
export const MODIFY_DOCUMENTS_DUE_DILIGENCE = `${STATE_KEY}/MODIFY_DOCUMENTS_DUE_DILIGENCE`;

// gestionale
export const RETRIEVE_ACCOUNT = `${STATE_KEY}/RETRIEVE_ACCOUNT`;
export const STORE_ACCOUNT = `${STATE_KEY}/STORE_ACCOUNT`;
export const UPSERT_ACCOUNT = `${STATE_KEY}/UPSERT_ACCOUNT`;
export const RETRIEVE_USER_LIST = `${STATE_KEY}/RETRIEVE_USER_LIST`;
export const STORE_USER_LIST = `${STATE_KEY}/STORE_USER_LIST`;
export const DELETE_USER = `${STATE_KEY}/DELETE_USER`;
export const SET_CURRENT_USER = `${STATE_KEY}/SET_CURRENT_USER`;
export const RETRIEVE_USER = `${STATE_KEY}/RETRIEVE_USER`;
export const UPSERT_USER = `${STATE_KEY}/UPSERT_USER`;
export const SET_LIST_CONFIG = `${STATE_KEY}/SET_LIST_CONFIG`;
export const RETRIEVE_ROLES = `${STATE_KEY}/RETRIEVE_ROLES`;
export const RETRIEVE_PEMRMISSIONS = `${STATE_KEY}/RETRIEVE_PEMRMISSIONS`;
export const STORE_ROLES = `${STATE_KEY}/STORE_ROLES`;
export const STORE_PERMISSIONS = `${STATE_KEY}/STORE_PERMISSIONS`;
export const RETRIEVE_ROLE = `${STATE_KEY}/RETRIEVE_ROLE`;
export const DELETE_ROLE = `${STATE_KEY}/DELETE_ROLE`;
export const SET_CURRENT_ROLE = `${STATE_KEY}/SET_CURRENT_ROLE`;
export const UPSERT_ROLE = `${STATE_KEY}/UPSERT_ROLE`;
// anagrafica
export const RETRIEVE_RELAZIONE_TIPO_LIST = `${STATE_KEY}/RETRIEVE_RELAZIONE_TIPO_LIST`;
export const STORE_RELAZIONE_TIPO_LIST = `${STATE_KEY}/STORE_RELAZIONE_TIPO_LIST`;
export const UPSERT_RELAZIONE_TIPO = `${STATE_KEY}/UPSERT_RELAZIONE_TIPO`;
export const DELETE_RELAZIONE_TIPO = `${STATE_KEY}/DELETE_RELAZIONE_TIPO`;
export const RETRIEVE_NATURA_GIURIDICA = `${STATE_KEY}/RETRIEVE_NATURA_GIURIDICA`;
export const STORE_NATURA_GIURIDICA = `${STATE_KEY}/STORE_NATURA_GIURIDICA`;
export const RETRIEVE_ANAGRAFICA_CATEGORIA = `${STATE_KEY}/RETRIEVE_ANAGRAFICA_CATEGORIA`;
export const STORE_ANAGRAFICA_CATEGORIA = `${STATE_KEY}/STORE_ANAGRAFICA_CATEGORIA`;
export const UPSERT_ANAGRAFICA_CATEGORIA = `${STATE_KEY}/UPSERT_ANAGRAFICA_CATEGORIA`;
export const DELETE_ANAGRAFICA_CATEGORIA = `${STATE_KEY}/DELETE_ANAGRAFICA_CATEGORIA`;
export const RETRIEVE_ANAGRAFICA_SPECIFICA = `${STATE_KEY}/RETRIEVE_ANAGRAFICA_SPECIFICA`;
export const STORE_ANAGRAFICA_SPECIFICA = `${STATE_KEY}/STORE_ANAGRAFICA_SPECIFICA`;
export const UPSERT_ANAGRAFICA_SPECIFICA = `${STATE_KEY}/UPSERT_ANAGRAFICA_SPECIFICA`;
export const DELETE_ANAGRAFICA_SPECIFICA = `${STATE_KEY}/DELETE_ANAGRAFICA_SPECIFICA`;
export const RETRIEVE_ANAGRAFICA_LIST = `${STATE_KEY}/RETRIEVE_ANAGRAFICA_LIST`;
export const STORE_ANAGRAFICA_LIST = `${STATE_KEY}/STORE_ANAGRAFICA_LIST`;
export const SET_CURRENT_ANAGRAFICA = `${STATE_KEY}/SET_CURRENT_ANAGRAFICA`;
export const DELETE_ANAGRAFICA = `${STATE_KEY}/DELETE_ANAGRAFICA`;
export const RETRIEVE_ANAGRAFICA = `${STATE_KEY}/RETRIEVE_ANAGRAFICA`;
export const UPSERT_ANAGRAFICA = `${STATE_KEY}/UPSERT_ANAGRAFICA`;
export const RETRIEVE_GRUPPO_LIST = `${STATE_KEY}/RETRIEVE_GRUPPO_LIST`;
export const RETRIEVE_GRUPPO = `${STATE_KEY}/RETRIEVE_GRUPPO`;
export const STORE_GRUPPO_LIST = `${STATE_KEY}/STORE_GRUPPO_LIST`;
export const UPSERT_GRUPPO = `${STATE_KEY}/UPSERT_GRUPPO`;
export const DELETE_GRUPPO = `${STATE_KEY}/DELETE_GRUPPO`;
export const SET_CURRENT_GRUPPO = `${STATE_KEY}/SET_CURRENT_GRUPPO`;
// pratiche
export const RETRIEVE_PRATICA_LIST = `${STATE_KEY}/RETRIEVE_PRATICA_LIST`;
export const STORE_PRATICA_LIST = `${STATE_KEY}/STORE_PRATICA_LIST`;
export const SET_CURRENT_PRATICA = `${STATE_KEY}/SET_CURRENT_PRATICA`;
export const DELETE_PRATICA = `${STATE_KEY}/DELETE_PRATICA`;
export const RETRIEVE_PRATICA = `${STATE_KEY}/RETRIEVE_PRATICA`;
export const UPSERT_PRATICA = `${STATE_KEY}/UPSERT_PRATICA`;
export const UPSERT_PRATICA_ONEDRIVE = `${STATE_KEY}/UPSERT_PRATICA_ONEDRIVE`;
export const RETRIEVE_PRATICA_MATERIA = `${STATE_KEY}/RETRIEVE_PRATICA_MATERIA`;
export const STORE_PRATICA_MATERIA = `${STATE_KEY}/STORE_PRATICA_MATERIA`;
export const RETRIEVE_DIPARTIMENTO_LIST = `${STATE_KEY}/RETRIEVE_DIPARTIMENTO_LIST`;
export const STORE_DIPARTIMENTO_LIST = `${STATE_KEY}/STORE_DIPARTIMENTO_LIST`;
export const UPSERT_DIPARTIMENTO = `${STATE_KEY}/UPSERT_DIPARTIMENTO`;
export const DELETE_DIPARTIMENTO = `${STATE_KEY}/DELETE_DIPARTIMENTO`;
// incarichi
export const RETRIEVE_INCARICO_LIST = `${STATE_KEY}/RETRIEVE_INCARICO_LIST`;
export const STORE_INCARICO_LIST = `${STATE_KEY}/STORE_INCARICO_LIST`;
export const SET_CURRENT_INCARICO = `${STATE_KEY}/SET_CURRENT_INCARICO`;
export const DELETE_INCARICO = `${STATE_KEY}/DELETE_INCARICO`;
export const RETRIEVE_INCARICO = `${STATE_KEY}/RETRIEVE_INCARICO`;
export const UPSERT_INCARICO = `${STATE_KEY}/UPSERT_INCARICO`;
// timesheets
export const RETRIEVE_TIMESHEET_TIPO_LIST = `${STATE_KEY}/RETRIEVE_TIMESHEET_TIPO_LIST`;
export const STORE_TIMESHEET_TIPO_LIST = `${STATE_KEY}/STORE_TIMESHEET_TIPO_LIST`;
export const UPSERT_TIMESHEET_TIPO = `${STATE_KEY}/UPSERT_TIMESHEET_TIPO`;
export const DELETE_TIMESHEET_TIPO = `${STATE_KEY}/DELETE_TIMESHEET_TIPO`;
export const RETRIEVE_TIMESHEET_LIST = `${STATE_KEY}/RETRIEVE_TIMESHEET_LIST`;
export const RETRIEVE_TIMESHEET_LIST_DOWNLOAD = `${STATE_KEY}/RETRIEVE_TIMESHEET_LIST_DOWNLOAD`;
export const STORE_TIMESHEET_LIST = `${STATE_KEY}/STORE_TIMESHEET_LIST`;
export const SET_CURRENT_TIMESHEET = `${STATE_KEY}/SET_CURRENT_TIMESHEET`;
export const DELETE_TIMESHEET = `${STATE_KEY}/DELETE_TIMESHEET`;
export const RETRIEVE_TIMESHEET = `${STATE_KEY}/RETRIEVE_TIMESHEET`;
export const UPSERT_TIMESHEET = `${STATE_KEY}/UPSERT_TIMESHEET`;
// eventi
export const RETRIEVE_EVENTO_TIPO_LIST = `${STATE_KEY}/RETRIEVE_EVENTO_TIPO_LIST`;
export const STORE_EVENTO_TIPO_LIST = `${STATE_KEY}/STORE_EVENTO_TIPO_LIST`;
export const RETRIEVE_EVENTO_LIST = `${STATE_KEY}/RETRIEVE_EVENTO_LIST`;
export const UPSERT_EVENTO_TIPO = `${STATE_KEY}/UPSERT_EVENTO_TIPO`;
export const DELETE_EVENTO_TIPO = `${STATE_KEY}/DELETE_EVENTO_TIPO`;
export const STORE_EVENTO_LIST = `${STATE_KEY}/STORE_EVENTO_LIST`;
export const SET_CURRENT_EVENTO = `${STATE_KEY}/SET_CURRENT_EVENTO`;
export const DELETE_EVENTO = `${STATE_KEY}/DELETE_EVENTO`;
export const RETRIEVE_EVENTO = `${STATE_KEY}/RETRIEVE_EVENTO`;
export const UPSERT_EVENTO = `${STATE_KEY}/UPSERT_EVENTO`;
// documenti
export const RETRIEVE_DOCUMENTO_TIPO_LIST = `${STATE_KEY}/RETRIEVE_DOCUMENTO_TIPO_LIST`;
export const STORE_DOCUMENTO_TIPO_LIST = `${STATE_KEY}/STORE_DOCUMENTO_TIPO_LIST`;
export const UPSERT_DOCUMENTO_TIPO = `${STATE_KEY}/UPSERT_DOCUMENTO_TIPO`;
export const DELETE_DOCUMENTO_TIPO = `${STATE_KEY}/DELETE_DOCUMENTO_TIPO`;
export const RETRIEVE_DOCUMENTO_CATEGORIA_LIST = `${STATE_KEY}/RETRIEVE_DOCUMENTO_CATEGORIA_LIST`;
export const STORE_DOCUMENTO_CATEGORIA_LIST = `${STATE_KEY}/STORE_DOCUMENTO_CATEGORIA_LIST`;
export const RETRIEVE_DOCUMENTO_LIST = `${STATE_KEY}/RETRIEVE_DOCUMENTO_LIST`;
export const STORE_DOCUMENTO_LIST = `${STATE_KEY}/STORE_DOCUMENTO_LIST`;
export const SET_CURRENT_DOCUMENTO = `${STATE_KEY}/SET_CURRENT_DOCUMENTO`;
export const DELETE_DOCUMENTO = `${STATE_KEY}/DELETE_DOCUMENTO`;
export const DELETE_DOCUMENTO_ONEDRIVE = `${STATE_KEY}/DELETE_DOCUMENTO_ONEDRIVE`;
export const RETRIEVE_DOCUMENTO = `${STATE_KEY}/RETRIEVE_DOCUMENTO`;
export const UPSERT_DOCUMENTO = `${STATE_KEY}/UPSERT_DOCUMENTO`;
export const UPSERT_DOCUMENTO_ONEDRIVE = `${STATE_KEY}/UPSERT_DOCUMENTO_ONEDRIVE`;
export const DOWNLOAD_DOCUMENTO = `${STATE_KEY}/DOWNLOAD_DOCUMENTO`;
export const UPLOAD_DOCUMENTO = `${STATE_KEY}/UPLOAD_DOCUMENTO`;
export const SEND_DOCUMENTO = `${STATE_KEY}/SEND_DOCUMENTO`;
// general
export const STORE_CURRENT_CREATE_SESSION = `${STATE_KEY}/STORE_CURRENT_CREATE_SESSION`;
export const RESET_CURRENT_CREATE_SESSION = `${STATE_KEY}/RESET_CURRENT_CREATE_SESSION`;
// onorario
export const RETRIEVE_TARIFFA_ORARIA_LIST = `${STATE_KEY}/RETRIEVE_TARIFFA_ORARIA_LIST`;
export const STORE_TARIFFA_ORARIA_LIST = `${STATE_KEY}/STORE_TARIFFA_ORARIA_LIST`;
export const UPSERT_TARIFFA_ORARIA = `${STATE_KEY}/UPSERT_TARIFFA_ORARIA`;
export const DELETE_TARIFFA_ORARIA = `${STATE_KEY}/DELETE_TARIFFA_ORARIA`;
export const RETRIEVE_ONORARIO_LIST = `${STATE_KEY}/RETRIEVE_ONORARIO_LIST`;
export const STORE_ONORARIO_LIST = `${STATE_KEY}/STORE_ONORARIO_LIST`;
export const SET_CURRENT_ONORARIO = `${STATE_KEY}/SET_CURRENT_ONORARIO`;
export const DELETE_ONORARIO = `${STATE_KEY}/DELETE_ONORARIO`;
export const RETRIEVE_ONORARIO = `${STATE_KEY}/RETRIEVE_ONORARIO`;
export const UPSERT_ONORARIO = `${STATE_KEY}/UPSERT_ONORARIO`;
export const RETRIEVE_RATA_LIST = `${STATE_KEY}/RETRIEVE_RATA_LIST`;
export const STORE_RATA_LIST = `${STATE_KEY}/STORE_RATA_LIST`;
export const SET_CURRENT_RATA = `${STATE_KEY}/SET_CURRENT_RATA`;
export const DELETE_RATA = `${STATE_KEY}/DELETE_RATA`;
export const RETRIEVE_RATA = `${STATE_KEY}/RETRIEVE_RATA`;
export const UPSERT_RATA = `${STATE_KEY}/UPSERT_RATA`;
// onorario incarico
export const RETRIEVE_ONORARIO_INCARICO_LIST = `${STATE_KEY}/RETRIEVE_ONORARIO_INCARICO_LIST`;
export const STORE_ONORARIO_INCARICO_LIST = `${STATE_KEY}/STORE_ONORARIO_INCARICO_LIST`;
export const SET_CURRENT_ONORARIO_INCARICO = `${STATE_KEY}/SET_CURRENT_ONORARIO_INCARICO`;
export const DELETE_ONORARIO_INCARICO = `${STATE_KEY}/DELETE_ONORARIO_INCARICO`;
export const RETRIEVE_ONORARIO_INCARICO = `${STATE_KEY}/RETRIEVE_ONORARIO_INCARICO`;
export const UPSERT_ONORARIO_INCARICO = `${STATE_KEY}/UPSERT_ONORARIO_INCARICO`;
export const RETRIEVE_RATA_INCARICO_LIST = `${STATE_KEY}/RETRIEVE_RATA_INCARICO_LIST`;
export const STORE_RATA_INCARICO_LIST = `${STATE_KEY}/STORE_RATA_INCARICO_LIST`;
export const SET_CURRENT_RATA_INCARICO = `${STATE_KEY}/SET_CURRENT_RATA_INCARICO`;
export const DELETE_RATA_INCARICO = `${STATE_KEY}/DELETE_RATA_INCARICO`;
export const RETRIEVE_RATA_INCARICO = `${STATE_KEY}/RETRIEVE_RATA_INCARICO`;
export const UPSERT_RATA_INCARICO = `${STATE_KEY}/UPSERT_RATA_INCARICO`;
// search
export const SET_SEARCH = `${STATE_KEY}/SET_SEARCH`;
export const SET_PRELOAD = `${STATE_KEY}/SET_PRELOAD`;
// fattura
export const RETRIEVE_SPESE_GENERALI_LIST = `${STATE_KEY}/RETRIEVE_SPESE_GENERALI_LIST`;
export const STORE_SPESE_GENERALI_LIST = `${STATE_KEY}/STORE_SPESE_GENERALI_LIST`;
export const UPSERT_SPESE_GENERALI = `${STATE_KEY}/UPSERT_SPESE_GENERALI`;
export const DELETE_SPESE_GENERALI = `${STATE_KEY}/DELETE_SPESE_GENERALI`;
export const RETRIEVE_FATTURA_LIST = `${STATE_KEY}/RETRIEVE_FATTURA_LIST`;
export const STORE_FATTURA_LIST = `${STATE_KEY}/STORE_FATTURA_LIST`;
export const SET_CURRENT_FATTURA = `${STATE_KEY}/SET_CURRENT_FATTURA`;
export const DELETE_FATTURA = `${STATE_KEY}/DELETE_FATTURA`;
export const RETRIEVE_FATTURA = `${STATE_KEY}/RETRIEVE_FATTURA`;
export const UPSERT_FATTURA = `${STATE_KEY}/UPSERT_FATTURA`;
export const RETRIEVE_NOTULA_LIST = `${STATE_KEY}/RETRIEVE_NOTULA_LIST`;
export const STORE_NOTULA_LIST = `${STATE_KEY}/STORE_NOTULA_LIST`;
// onorario fattura
export const RETRIEVE_ONORARIO_FATTURA_LIST = `${STATE_KEY}/RETRIEVE_ONORARIO_FATTURA_LIST`;
export const STORE_ONORARIO_FATTURA_LIST = `${STATE_KEY}/STORE_ONORARIO_FATTURA_LIST`;
export const SET_CURRENT_ONORARIO_FATTURA = `${STATE_KEY}/SET_CURRENT_ONORARIO_FATTURA`;
export const DELETE_ONORARIO_FATTURA = `${STATE_KEY}/DELETE_ONORARIO_FATTURA`;
export const RETRIEVE_ONORARIO_FATTURA = `${STATE_KEY}/RETRIEVE_ONORARIO_FATTURA`;
export const UPSERT_ONORARIO_FATTURA = `${STATE_KEY}/UPSERT_ONORARIO_FATTURA`;
export const RETRIEVE_RATA_FATTURA_LIST = `${STATE_KEY}/RETRIEVE_RATA_FATTURA_LIST`;
export const STORE_RATA_FATTURA_LIST = `${STATE_KEY}/STORE_RATA_FATTURA_LIST`;
export const SET_CURRENT_RATA_FATTURA = `${STATE_KEY}/SET_CURRENT_RATA_FATTURA`;
export const DELETE_RATA_FATTURA = `${STATE_KEY}/DELETE_RATA_FATTURA`;
export const RETRIEVE_RATA_FATTURA = `${STATE_KEY}/RETRIEVE_RATA_FATTURA`;
export const UPSERT_RATA_FATTURA = `${STATE_KEY}/UPSERT_RATA_FATTURA`;
// timesheet chart
export const RETRIEVE_TIMESHEET_CHART_LIST = `${STATE_KEY}/RETRIEVE_TIMESHEET_CHART_LIST`;
export const RETRIEVE_TIMESHEET_CHART_LIST_DOWNLOAD = `${STATE_KEY}/RETRIEVE_TIMESHEET_CHART_LIST_DOWNLOAD`;
export const STORE_TIMESHEET_CHART_LIST = `${STATE_KEY}/STORE_TIMESHEET_CHART_LIST`;
// visure
export const RETRIEVE_VISURE_PRICES = `${STATE_KEY}/RETRIEVE_VISURE_PRICES`;
export const STORE_VISURE_PRICES = `${STATE_KEY}/STORE_VISURE_PRICES`;
// fatturazione elettronica
export const INVIA_FATTURA_ELETTRONICA = `${STATE_KEY}/INVIA_FATTURA_ELETTRONICA`;
// depositi
export const RETRIEVE_DEPOSITO_LIST = `${STATE_KEY}/RETRIEVE_DEPOSITO_LIST`;
export const STORE_DEPOSITO_LIST = `${STATE_KEY}/STORE_DEPOSITO_LIST`;
export const SET_CURRENT_DEPOSITO = `${STATE_KEY}/SET_CURRENT_DEPOSITO`;
export const DELETE_DEPOSITO = `${STATE_KEY}/DELETE_DEPOSITO`;
export const RETRIEVE_DEPOSITO = `${STATE_KEY}/RETRIEVE_DEPOSITO`;
export const UPSERT_DEPOSITO = `${STATE_KEY}/UPSERT_DEPOSITO`;
// notifica
export const RETRIEVE_NOTIFICA_LIST = `${STATE_KEY}/RETRIEVE_NOTIFICA_LIST`;
export const STORE_NOTIFICA_LIST = `${STATE_KEY}/STORE_NOTIFICA_LIST`;
export const SET_CURRENT_NOTIFICA = `${STATE_KEY}/SET_CURRENT_NOTIFICA`;
export const DELETE_NOTIFICA = `${STATE_KEY}/DELETE_NOTIFICA`;
export const RETRIEVE_NOTIFICA = `${STATE_KEY}/RETRIEVE_NOTIFICA`;
export const UPSERT_NOTIFICA = `${STATE_KEY}/UPSERT_NOTIFICA`;
// configuration
export const RETRIEVE_CONFIGURATION_LIST = `${STATE_KEY}/RETRIEVE_CONFIGURATION_LIST`;
export const STORE_CONFIGURATION_LIST = `${STATE_KEY}/STORE_CONFIGURATION_LIST`;
export const SET_CURRENT_CONFIGURATION = `${STATE_KEY}/SET_CURRENT_CONFIGURATION`;
export const DELETE_CONFIGURATION = `${STATE_KEY}/DELETE_CONFIGURATION`;
export const RETRIEVE_CONFIGURATION = `${STATE_KEY}/RETRIEVE_CONFIGURATION`;
export const UPSERT_CONFIGURATION = `${STATE_KEY}/UPSERT_CONFIGURATION`;
// modal
export const SET_MODAL_STATE = `${STATE_KEY}/SET_MODAL_STATE`;
export const STORE_UNREAD_NOTICES = `${STATE_KEY}/STORE_UNREAD_NOTICES`;
// spesa
export const RETRIEVE_SPESA_CATEGORIA_LIST = `${STATE_KEY}/RETRIEVE_SPESA_CATEGORIA_LIST`;
export const STORE_SPESA_CATEGORIA_LIST = `${STATE_KEY}/STORE_SPESA_CATEGORIA_LIST`;
export const UPSERT_SPESA_CATEGORIA = `${STATE_KEY}/UPSERT_SPESA_CATEGORIA`;
export const DELETE_SPESA_CATEGORIA = `${STATE_KEY}/DELETE_SPESA_CATEGORIA`;
export const RETRIEVE_SPESA_LIST = `${STATE_KEY}/RETRIEVE_SPESA_LIST`;
export const STORE_SPESA_LIST = `${STATE_KEY}/STORE_SPESA_LIST`;
export const SET_CURRENT_SPESA = `${STATE_KEY}/SET_CURRENT_SPESA`;
export const DELETE_SPESA = `${STATE_KEY}/DELETE_SPESA`;
export const RETRIEVE_SPESA = `${STATE_KEY}/RETRIEVE_SPESA`;
export const UPSERT_SPESA = `${STATE_KEY}/UPSERT_SPESA`;

// microsoft
export const STORE_MICROSOFT_ACCOUNT_STATUS = `${STATE_KEY}/STORE_MICROSOFT_ACCOUNT_STATUS`;
export const STORE_MICROSOFT_PROFILE_STATUS = `${STATE_KEY}/STORE_MICROSOFT_PROFILE_STATUS`;
export const STORE_CAN_CONFIGURE_MICROSOFT = `${STATE_KEY}/STORE_CAN_CONFIGURE_MICROSOFT`;

// apicore
export const LOAD_FROM_CLOUD = `${STATE_KEY}/LOAD_FROM_CLOUD`;
export const SAVE_TO_CLOUD = `${STATE_KEY}/SAVE_ON_CLOUD`;
export const EXPORT_PDF = `${STATE_KEY}/EXPORT_PDF`;
export const ADD_BOOKMARK_TO_PDF = `${STATE_KEY}/ADD_BOOKMARK_TO_PDF`;
export const SET_CURRENT_CLOUD_DOCUMENT = `${STATE_KEY}/SET_CURRENT_CLOUD_DOCUMENT`;
