import { ofType } from 'redux-observable';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { concat, defer, EMPTY, from, of } from 'rxjs';
import { navigate } from '@reach/router';
import {
  httpMethods,
  singleFileUpload,
} from '../../../../../services/http-service';
import { UPLOAD_DOCUMENTO, UPSERT_DOCUMENTO_ONEDRIVE } from '../../data.const';
import { setLoaders, setPopupInfo } from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const uploadDocumentoEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTO),
    map(({ payload }: any) => payload),
    tap((p) => console.log(`[epic ${UPLOAD_DOCUMENTO}]`, p)),
    withLatestFrom(state$),
    switchMap(([payload, _state]) => {
      const { items, canNavigate } = payload;
      return concat(
        of(setLoaders({ documentoLoader: true })),
        defer(() =>
          concat(
            from(items).pipe(
              concatMap((item: any) =>
                from(
                  singleFileUpload({
                    path: `documento`,
                    method: httpMethods.POST,
                    body: item,
                  })
                ).pipe(
                  map((p: any) => {
                    if (p?.status > 299) {
                      throw new Error(p.message);
                    }
                    return {
                      type: UPSERT_DOCUMENTO_ONEDRIVE,
                      payload: p?.data?.result,
                    };
                  }),
                  catchError((err) => {
                    console.error(`[epic ${UPLOAD_DOCUMENTO}] error`, err);
                    return EMPTY;
                  })
                )
              )
            ),
            of(
              setPopupInfo({
                opened: true,
                message:
                  items.length > 1
                    ? 'Documenti caricati'
                    : 'Documento caricato',
                type: 'GOOD',
                timer: 3000,
              })
            )
          )
        ),
        of(setLoaders({ documentoLoader: false })).pipe(
          tap(() => {
            if (canNavigate) {
              navigate(`${rootConfig.app_path}/gestionale/documenti`).then(
                () => null
              );
            }
          })
        )
      );
    })
  );

export default uploadDocumentoEpic;
